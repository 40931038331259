import React, { useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { useThemeContext } from "../context/ThemeProvider";

const SignIn = () => {
  const params = new URLSearchParams(window.location.search);
  const paramEmail = params.get("email");
  const location = useLocation();
  const [error, setError] = useState(null);
  const { user, signIn } = useAuth();
  const email = useRef();
  const password = useRef();
  const { from } = location.state || { from: { pathname: "/" } };
  const redirectURL = ".." + window.location.search;
  const createAccountURL = "../createaccount" + window.location.search;
  const { colors } = useThemeContext();

  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);
    try {
      await signIn(email.current.value, password.current.value);
      // navigate(from.pathname, { replace: false, relative:"route" });
      // let redirectURL = from.pathname;
      // if (referral_link) {
      //   redirectURL += `?referral_link=${referral_link}`;
      // }
      // console.log(redirectURL)

      // redirect(redirectURL);
    } catch (err) {
      setError(err.message.replace(/Firebase: /, ""));
      console.log(err);
    }
  }

  if (user) {
    return <Navigate to={redirectURL} replace />;
  }
  return (
    <Form className="anim-card max-w-md w-full flex flex-col gap-[34px] mx-auto px-3 text-left" onSubmit={handleSubmit}>
      <p className="text-xl text-center m-0 font-bold leading-none">Welcome Back</p>

      <div className="flex flex-col relative mb-[20px]">
        <div
          className="flex flex-col gap-[10px] relative inset-0 rounded-md"
          style={{
            padding: "20px 28px 30px 28px",
            boxShadow: "6px 6px 0px color-mix(in srgb, #d4d4d4 42%, transparent)",
            border: "1px solid color-mix(in srgb, #d4d4d4 42%, transparent)",
          }}
        >
          <Form.Group>
            <Form.Control type="email" placeholder="Email" ref={email} defaultValue={paramEmail} required />
          </Form.Group>
          <Form.Group className="flex flex-col gap-1 items-end">
            <Form.Control type="password" placeholder="Password" ref={password} required />
          </Form.Group>
          <Link
            to={process.env.PUBLIC_URL + "/resetpassword"}
            state={{ from: from }}
            className="text-xs no-underline text-inherit opacity-70 hover:!opacity-100 self-end"
          >
            Forgot password?
          </Link>
          {!!error && (
            <Alert variant="danger" className="fade-down m-0">
              {error}
            </Alert>
          )}
        </div>
        <Button
          className="mx-auto w-4/6 text-xl py-[6px] absolute -bottom-[20px] left-0 right-0 !leading-none"
          variant="primary"
          type="submit"
        >
          Log In
        </Button>
      </div>
      <Form.Group className="flex flex-col gap-2 justify-center mx-auto mt-0 text-center">
        <Form.Text className="text-sm text-black">New to My Challenge Canada?</Form.Text>
        <Form.Text>
          <Link to={createAccountURL} state={{ from: from }} className={`px-3 py-1 rounded-md `}>
            Create your Account
          </Link>
        </Form.Text>
      </Form.Group>
      {/* <Form.Group className="flex flex-wrap justify-center mx-auto mt-0 text-center">
        <Form.Text className="mt-0">
          <Link to={process.env.PUBLIC_URL + "/resetpassword"} state={{ from: from }}>
            Forgot your password?
          </Link>
        </Form.Text>
      </Form.Group> */}
    </Form>
  );
};

export default SignIn;
