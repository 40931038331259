import React, { useState } from "react";
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import RacePage from "./AppPages/RacePage";
import CreateAccount from "./AppPages/CreateAccount";
import SignIn from "./AppPages/SignIn";
import { useAuth } from "./context/AuthProvider";
import { AccountIcon, ArrowBackIcon } from "./AppPages/util/Icons";
import HomePage from "./AppPages/UserPage/HomePage";
import ResetPassword from "./AppPages/ResetPassword";
import ReferralLandingPage from "./AppPages/ReferralLandingPage";
import CelebrateCanada from "./AppPages/CelebrateCanada";

function App() {
  const { user, userLoading } = useAuth();
  if (userLoading) {
    return;
  }
  return (
    <div className="relative grid grid-rows-[auto,1fr,auto] min-h-screen">
      <Header user={user} />
      <div className="relative">
        <div className="w-full h-full px-3 mb-5">
          <Routes>
            {user ? (
              <>
                <Route path="/*" element={<HomePage />} />
              </>
            ) : (
              <>{/* <Route index element={<Navigate to="createaccount" replace={true} />} /> */}</>
            )}

            <Route path="register/" element={<ReferralLandingPage />} />

            <Route path="register/CelebrateCanada/*" element={<CelebrateCanada />} />

            <Route path="register/:id/*" element={<RacePage />} />

            <Route
              path="signin"
              element={
                <div className="mt-[35px]">
                  <SignIn />
                </div>
              }
            />
            <Route
              path="createaccount"
              element={
                <div className="mt-[35px]">
                  <CreateAccount />
                </div>
              }
            />
            <Route
              path="resetpassword"
              element={
                <div className="mt-5">
                  <ResetPassword />
                </div>
              }
            />
            <Route path="*" element={<Navigate to="createaccount" replace={true} />} />
            {/* <Route path="404" element={<h1>404</h1>} /> */}
          </Routes>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;

function Header({ user }) {
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const showBackButton = /^\/dashboard\/|^\/account|^\/leaderboard\//.test(currentLocation.pathname);

  function goBack() {
    navigate(-1);
  }
  let color = "black";
  if (window.location.origin === "https://mcc-staging-4525c.web.app" || window.location.origin === "http://localhost:3000") {
    color = "darkorange";
  }

  return (
    <header className="w-full h-16" style={{ backgroundColor: color }}>
      <div className="h-full mx-auto w-full max-w-5xl relative grid grid-cols-[min-content,1fr,min-content] justify-center items-center">
        <div className="h-full grid items-center">
          {showBackButton && (
            <div onClick={(e) => goBack()} className="rounded-full cursor-pointer p-1 ml-3">
              <ArrowBackIcon color="white" size={28} />
            </div>
          )}
        </div>
        <Link to="/">
          <img
            className="max-h-12 py-[0.35rem] mx-auto"
            src={process.env.PUBLIC_URL + `/img/mcc-logo-transparent.png`}
            alt="My Challenge Canada Logo"
          />
        </Link>
        <div className="h-full">{user && <AccountButton />}</div>
      </div>
    </header>
  );
}

function Footer() {
  return (
    <footer className="w-full bg-black text-white text-center text-xs py-4 ">
      <p className="mb-2">Made With 🍁 In Canada</p>
      <p className="mb-2 underline">hello@mychallengecanada.ca</p>
      <img className="mx-auto h-6 mb-2" src={process.env.PUBLIC_URL + `/img/api_logo_cptblWith_strava_horiz_white.png`} alt="Strava Logo" />
      <a
        href="https://mychallengecanada.ca/cookies-terms-and-privacy-policy/"
        target="_blank"
        rel="noreferrer"
        className="text-white pb-3 block"
      >
        Cookies, Privacy And Terms Policies
      </a>
      <p className="mb-0"> © My Challenge Canada 2024</p>
    </footer>
  );
}

function AccountButton() {
  const { user, logOut } = useAuth();
  const [showModal, setShowModal] = useState(false);

  return (
    <nav
      className="relative h-full flex justify-end items-center"
      //  onBlur={(e) => setShowModal(false)}
    >
      <div
        className={"h-full w-full px-3 flex items-center hover:bg-slate-600 cursor-pointer " + (showModal ? "bg-slate-600" : "")}
        tabIndex="1"
        // onFocus={(e) => {
        //   setShowModal(true);
        //   clearTimeout(timeout);
        // }}
        onClick={(e) => {
          setShowModal((prev) => !prev);
        }}
        onBlur={(e) => {
          if (e.target.contains(e.relatedTarget)) {
            return;
          }
          setShowModal(false);
        }}
      >
        <AccountIcon color="white" />
        {showModal && (
          <div
            className="absolute rounded-none w-max px-5 pt-3 top-full right-0 bg-slate-300 z-50"
            // className="absolute top-full bg-slate-300 p-3 min-w-max w-60"
          >
            <p className="m-0">{user.email}</p>
            <hr />
            <Link to="/" href="/" className="cursor-pointer no-underline block  mb-1 py-2 text-black">
              Go to my homepage
            </Link>
            <Link to="/account" className="cursor-pointer no-underline block mb-1  text-black py-2">
              Account settings
            </Link>
            <Link to="/referralhub" className="cursor-pointer no-underline block mb-1  text-black py-2">
              Referral hub
            </Link>
            <hr></hr>
            <p onClick={(e) => logOut()} className="cursor-pointer py-2 text-red-700">
              Logout
            </p>
          </div>
        )}
      </div>
    </nav>
  );
}
