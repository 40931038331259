import { Button, Form, InputGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { collection, getDocs, query, where, orderBy, getDoc, doc } from "firebase/firestore";
import { useAuth } from "../../context/AuthProvider";
import CreateAccount from "../CreateAccount";
import SignIn from "../SignIn";
import { db } from "../../firebase";
import { useMultiStepForm } from "../hooks/useMultiStepForm";
import { createCheckout, onCreateUser, writeUserDetails } from "../../func/writeFunctions";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useUserContext } from "../../context/UserProvider";
import TeamForm from "./TeamForm";
import { ConfirmationPage } from "./ConfirmationPage";
import { TruckIcon } from "../util/Icons";
import { GiftCodesForm } from "./GiftCodesForm";

const RacePage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referral_link = params.get("referral_link");
  const promo_code = params.get("promo_code");
  const navigate = useNavigate();
  const { user } = useAuth();
  let { id } = useParams();
  const [priceList, setPriceList] = useState([]);
  const [race, setRace] = useState(null);
  const [checkoutError, setCheckoutError] = useState(null);

  const formsArray = [
    <RaceForm priceList={priceList} race={race} />,
    <GiftCodesForm priceList={priceList} race={race} />,
    <DonationForm />,
    <ProductsForm />,
  ];
  // if (!promo_code) {
  //   formsArray.splice(1, 0, <GiftCodesForm priceList={priceList} race={race} />);
  // }
  const { CurrentForm, formDetails, formLoading } = useMultiStepForm(formsArray, handleSubmit);

  const priceMap = new Map();
  priceList.forEach((price) => {
    priceMap.set(price.id, price.data);
  });

  useEffect(() => {
    const q = query(collection(db, "stripe_products"), where("stripe_metadata_url", "==", id));
    async function fetchRace() {
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs[0]) {
        if (querySnapshot.docs[0].data().metadata.type !== "race" || !querySnapshot.docs[0].data().active) {
          navigate("/404", { replace: true });
        }
        let priceMap = [];
        let snapRace = querySnapshot.docs[0];
        const prices = await getDocs(query(collection(db, "stripe_products", snapRace.id, "prices"), orderBy("unit_amount", "asc")));
        prices.forEach((price) => {
          if (price.data().active) {
            const docID = price.data()?.metadata?.doc_id ? price.data().metadata.doc_id : price.id;
            // console.log(docID)
            priceMap.push({ id: docID, data: price.data() });
          }
        });
        setRace({ id: snapRace.id, data: snapRace.data() });
        setPriceList(priceMap);
        return;
      }
      navigate("/404", { replace: true });
    }
    fetchRace();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [CurrentForm]);

  async function handleSubmit(formDetails) {
    let extraUserDetails = {
      raceId: race.id,
    };
    priceList.forEach((price) => {
      if (price.id === formDetails.distanceId) {
        extraUserDetails.distanceString = price.data.description;
        extraUserDetails.distancePrice = price.data.unit_amount;
      }
    });
    // console.log(extraUserDetails);
    const q = query(collection(db, "stripe_products"), where("name", "==", "Service Fee"));
    try {
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs[0]) {
        const serviceFeeId = querySnapshot.docs[0].id;
        const serviceFeePrices = await getDocs(
          query(collection(db, "stripe_products", serviceFeeId, "prices"), orderBy("unit_amount", "desc"))
        );
        if (serviceFeePrices.docs[0]) {
          extraUserDetails.serviceFeePriceId = serviceFeePrices.docs[0].id;
        }
      }

      const finalFormDetails = { ...formDetails, ...extraUserDetails };
      if (promo_code) {
        finalFormDetails.promoCode = promo_code;
      }

      await onCreateUser(user);
      await writeUserDetails(user, finalFormDetails);
      // await writeUserToRace(user, finalFormDetails);
      // console.log("Submitting...", finalFormDetails, race, id);

      const createCheckoutError = await createCheckout(user, finalFormDetails, race, id, priceMap, referral_link);
      console.log(createCheckoutError);
      if (createCheckoutError) {
        setCheckoutError(createCheckoutError);
        return true;
      }
      // navigate("success", { replace: true });
    } catch (err) {
      console.log(err);
      return true;
    }
  }

  if (priceList.length <= 0) {
    return;
  }

  // console.log(race.data)
  const selectedRace = race.data;
  const createAccountURL = "createaccount" + window.location.search;
  // if (referral_link) {
  //   createAccountURL += `?referral_link=${referral_link}`;
  // }
  return (
    <div className="relative mx-auto max-w-3xl mt-6 w-full">
      {/* <div className="mt-3 max-w-2xl md:w-full">
        {race && !(selectedRace.metadata.checkoutBanner && window.location.pathname.includes("createaccount")) && (
          <div className="grid grid-cols-[62px,1fr] gap-3 items-center mb-4 px-2">
            <img src={selectedRace?.images[0]} alt={`${selectedRace?.name} Logo`} />
            <div>
              <h2 className="my-0">{selectedRace?.name}</h2>
              <p className="my-0 text-sm">
                {selectedRace.metadata.userStartDate} - {selectedRace.metadata.userEndDate}
              </p>
            </div>
          </div>
        )}
      </div> */}
      <div className="relative">
        <Routes>
          <Route
            path={`/`}
            element={
              user ? (
                checkoutError ? (
                  <div className="text-center">
                    <p>The coupon code entered cannot be redeemed as there are no socks in your cart. Make sure to add socks to your cart.</p>
                    <Button className="!text-sm" onClick={() => setCheckoutError(null)}>
                      Go back
                    </Button>
                  </div>
                ) : (
                  <CurrentForm />
                )
              ) : (
                <Navigate to={createAccountURL} state={{ from: { pathname: window.location.href } }} />
              )
            }
          ></Route>
          <Route path={`/confirmation`} element={user ? <ConfirmationPage raceName={id} /> : <Navigate to={createAccountURL} />}></Route>
          <Route
            path={`/signin`}
            element={
              <>
                {selectedRace.metadata.checkoutBanner && (
                  <picture>
                    <img
                      className="w-full max-w-lg mb-4 mx-auto text-center text-sm"
                      src={selectedRace.metadata.checkoutBanner}
                      alt="Promotional banner featuring race details."
                      title="Race to the Finish"
                    ></img>
                  </picture>
                )}
                <SignIn />
              </>
            }
          ></Route>
          <Route
            path={`/createaccount`}
            element={
              <>
                {selectedRace.metadata.checkoutBanner && (
                  <picture>
                    {/* <source srcSet={process.env.PUBLIC_URL + `/img/${selectedRace.metadata.checkoutBanner}.png`} type="image/png" /> */}
                    <img
                      className="w-full max-w-lg mb-4 mx-auto text-center text-sm"
                      src={selectedRace.metadata.checkoutBanner}
                      alt="Promotional banner featuring race details."
                      title="Race to the Finish"
                    ></img>
                  </picture>
                )}
                <CreateAccount />
              </>
            }
          ></Route>
          <Route path="*" element={<Navigate to="../" />} />
        </Routes>
      </div>
    </div>
  );
};

export default RacePage;

function RaceForm({ priceList, race }) {
  const { userDetails, userData } = useUserContext();
  const [country, setCountry] = useState(userDetails?.country || "Canada");
  const [region, setRegion] = useState(userDetails?.state || "");
  const [selectedRace, setSelectedRace] = useState(null);

  const allowTeams = race.data.metadata.teams === "true";

  useEffect(() => {
    setCountry(userDetails?.country);
    setRegion(userDetails?.state);
  }, [userDetails]);

  function formatPhone(e) {
    const phone = e.target.value.replace(/\D/g, "");
    // console.log(phone, e.target.value);

    let formattedPhone = "";
    if (phone.length > 3) {
      formattedPhone += phone.slice(0, 3) + "-";
      if (phone.length > 6) {
        formattedPhone += phone.slice(3, 6) + "-" + phone.slice(6);
      } else {
        formattedPhone += phone.slice(3);
      }
    } else {
      formattedPhone = phone;
    }
    e.target.value = formattedPhone;
  }

  const earlyBirdEnd = new Date(race.data.metadata.early_end);
  earlyBirdEnd.setDate(earlyBirdEnd.getDate() + 1);
  earlyBirdEnd.setUTCHours(7);

  const timeNow = new Date();
  const diff = new Date(earlyBirdEnd.getTime() - timeNow.getTime());

  const isEarly = diff > 0;

  // console.log(isEarly);

  return (
    <>
      <Form.Group className="mt-3">
        <h3 className="mb-3">Choose your distance</h3>
        {priceList.map((price, index) => {
          return (
            <Form.Check
              key={price.id}
              type="radio"
              label={
                <Form.Label className="cursor-pointer">
                  <p className="mb-1 font-semibold">
                    {`${price.data.description}${price.data.metadata.description ? " - " : ""}${price.data.metadata.description || ""}`}{" "}
                    {userData && userData?.subscribedRaces && !!userData.subscribedRaces[price.id] && (
                      <span className="block m-0 text-green-700"> Already Registered!</span>
                    )}
                  </p>
                  {isEarly ? (
                    <>
                      <p className="mb-2 text-sm">
                        Early Bird - ${price.data.metadata.early_price}{" "}
                        {`(${race.data.metadata.early_start} - ${race.data.metadata.early_end})`}
                      </p>
                      <p className="mb-2 text-sm text-[#6B6B6B] line-through">
                        Regular - ${price.data.metadata.regular_price}{" "}
                        {`(${race.data.metadata.regular_start} - ${race.data.metadata.regular_end})`}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="mb-2 text-sm text-[#6B6B6B] line-through">
                        Early Bird - ${price.data.metadata.early_price}{" "}
                        {`(${race.data.metadata.early_start} - ${race.data.metadata.early_end})`}
                      </p>
                      <p className="mb-2 text-sm">
                        Regular - ${price.data.metadata.regular_price}{" "}
                        {`(${race.data.metadata.regular_start} - ${race.data.metadata.regular_end})`}
                      </p>
                    </>
                  )}

                  {/* <p className="mb-2 text-sm text-[#6B6B6B]">
                    ${price.data.metadata.regular_price}{" "}
                    {`(${race.data.metadata.regular_start} - ${race.data.metadata.regular_end})`}
                  </p> */}
                </Form.Label>
              }
              name="distanceId"
              id={price.id}
              onChange={(e) => setSelectedRace(price.id)}
              value={price.id}
              disabled={userData ? (userData.subscribedRaces ? !!userData.subscribedRaces[price.id] : false) : false}
              required
            />
          );
        })}
      </Form.Group>
      <Form.Group className="mb-3">
        <h3 className="text-2xl text-left">Participant Details</h3>
        <div className="flex items-center mb-3">
          <TruckIcon size={22} className="inline mr-3" />
          <p className="inline m-0 underline">Your SWAG kit will be shipped to this address.</p>
        </div>
        {userDetails && (
          <div className="flex">
            <p>Is this information up to date?</p>
          </div>
        )}
        <Form.Group className="flex gap-3 mt-2">
          <Form.Group className="w-full">
            <Form.Label htmlFor="firstName">First Name</Form.Label>
            <Form.Control id="firstName" name="firstName" type="text" required defaultValue={userDetails?.firstName} />
          </Form.Group>
          <Form.Group className="w-full">
            <Form.Label htmlFor="lastName">Last Name</Form.Label>
            <Form.Control id="lastName" name="lastName" type="text" required defaultValue={userDetails?.lastName} />
          </Form.Group>
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label htmlFor="address">Address</Form.Label>
          <Form.Control id="address" name="address" type="text" required defaultValue={userDetails?.address} />
        </Form.Group>
        <Form.Group className="flex gap-3 mt-2">
          <Form.Group className="w-full">
            <Form.Label htmlFor="country">Country</Form.Label>
            <CountryDropdown
              id="country"
              name="country"
              country={country}
              onChange={(e) => setCountry(e)}
              value={country}
              classes="form-select"
              required
            />
            {/* <Form.Control id="country" name="country" type="text" defaultValue={userDetails?.country} /> */}
          </Form.Group>
          <Form.Group className="w-full">
            <Form.Label htmlFor="state">State / Province / Region</Form.Label>
            <RegionDropdown
              id="state"
              name="state"
              country={country}
              onChange={(e) => setRegion(e)}
              value={region}
              classes="form-select"
              required
            />
            {/* <Form.Control id="state" name="state" type="text" defaultValue={userDetails?.state} /> */}
          </Form.Group>
        </Form.Group>
        <Form.Group className="flex gap-3 mt-2">
          <Form.Group className="w-full">
            <Form.Label htmlFor="city">City</Form.Label>
            <Form.Control id="city" name="city" type="text" required defaultValue={userDetails?.city} />
          </Form.Group>
          <Form.Group className="w-full">
            <Form.Label htmlFor="postalCode">Postal Code</Form.Label>
            <Form.Control id="postalCode" name="postalCode" type="text" required defaultValue={userDetails?.postalCode} />
          </Form.Group>
        </Form.Group>
        <Form.Group className="flex gap-3 mt-2">
          <Form.Group className="w-full">
            <Form.Label htmlFor="phoneNumber">Phone Number</Form.Label>
            <Form.Control
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              required
              maxLength={12}
              minLength={10}
              pattern="\d{3}-\d{3}-\d{4}"
              defaultValue={userDetails?.phoneNumber}
              placeholder="123-456-7890"
              onChange={formatPhone}
            />
          </Form.Group>
        </Form.Group>
      </Form.Group>
      {allowTeams && <TeamForm selectedRace={selectedRace} />}
      {!allowTeams && (
        <Form.Group className="mx-auto w-full mb-3 pt-3">
          <Button size="" className="w-full mx-auto block py-2" type="submit">
            Continue
          </Button>
        </Form.Group>
      )}
    </>
  );
}

function ProductsForm() {
  const [products, setProducts] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const promo_product = params.get("promo_product");
  // const promo_code = params.get("promo_code");

  useEffect(() => {
    async function fetchProducts() {
      const productDocs = await getDocs(query(collection(db, "stripe_products"), where("stripe_metadata_type", "==", "product")));
      if (productDocs.docs.length > 0) {
        let tempProducts = [];
        productDocs.docs.forEach(async (product, i) => {
          if (!product.data().active) return;
          let priceQuery = await getDocs(query(collection(db, "stripe_products", product.id, "prices"), orderBy("unit_amount", "asc")));
          let priceData = priceQuery.docs[0];
          tempProducts = [
            {
              id: product.id,
              data: product.data(),
              priceData: { id: priceData?.id, data: priceData?.data() },
            },
            ...tempProducts,
          ];
          setProducts(tempProducts);
        });
      }
    }
    fetchProducts();
  }, []);
  if (!products) {
    return;
  }
  return (
    <Form.Group className="mt-3">
      <h3 className="mb-3">Improve Your Experience</h3>
      <Form.Group>
        {products.map((product, i) => {
          const isPromoProduct = !!promo_product ? promo_product === product.id : false;
          return (
            <div key={product.id} className="mt-10 first:mt-3">
              <h5 className="text-[#006BC1] font-semibold">{product.data.name}</h5>
              <p className="whitespace-pre-line">{product.data.description.replace(/\\n/g, "\n")}</p>
              {product.data.metadata.extra_info && (
                <p className="whitespace-pre-line">{product.data.metadata.extra_info.replace(/\\n/g, "\n")}</p>
              )}
              <img className="max-h-48 my-3" src={product.data.images[0]} alt={product.data.name} title={product.data.name} />
              <Form.Label
                className="bg-slate-300 w-full flex justify-start items-center p-3 gap-3 cursor-pointer"
                htmlFor={product.priceData.id}
              >
                <Form.Check
                  className="!m-0"
                  type="checkbox"
                  name={`product_${product.id}`}
                  id={product.priceData.id}
                  value={product.priceData.id}
                  defaultChecked={isPromoProduct}
                  // disabled={isPromoProduct}
                />
                <p className="font-semibold my-0">{`${product.data.metadata.cta} $${product.priceData.data.unit_amount / 100}`}</p>
                {/* {isPromoProduct && <span className="text-green-700">* FREE *</span>} */}
              </Form.Label>
            </div>
          );
        })}
      </Form.Group>
      <Form.Group className="mx-auto w-full mb-3 pt-3">
        <Button className="w-full mx-auto block py-2" type="submit">
          Continue
        </Button>
      </Form.Group>
    </Form.Group>
  );
}

function DonationForm() {
  const [donationAmount, setDonationAmount] = useState(0);
  const [charitiesData, setCharitiesData] = useState(null);
  const donationIntent = donationAmount ? (parseInt(donationAmount) > 0 ? true : false) : false;

  useEffect(() => {
    if (!charitiesData) fetchCharities();
    async function fetchCharities() {
      try {
        const charitiesDoc = await getDoc(doc(db, "options", "checkoutCharities"));
        setCharitiesData(charitiesDoc.data());
      } catch (err) {
        console.log(err.message);
      }
    }
  }, []);
  return (
    <Form.Group className="mt-3 flex flex-col gap-3">
      <h3 className="mb-3">
        Make a donation <span>(optional)</span>
      </h3>
      <h5>Select Donation Amount</h5>

      <InputGroup>
        <InputGroup.Text>$</InputGroup.Text>
        <Form.Control
          className=""
          type="number"
          placeholder="0"
          name="donation"
          id="donation"
          min={0}
          pattern="\d+"
          value={donationAmount}
          onChange={(e) => setDonationAmount(e.target.value)}
          onFocus={(e) => {
            if (e.target.value === "0") {
              e.target.value = "";
            }
          }}
          onBlur={(e) => {
            if (e.target.value === "") {
              e.target.value = "0";
            }
          }}
        />
      </InputGroup>

      <h5 className="mt-3">Select Organization to Donate</h5>
      <div className="flex flex-col">
        {charitiesData &&
          Object.keys(charitiesData)
            .sort((a, b) => {
              const aOrder = !!charitiesData[a].order ? charitiesData[a].order : 99;
              const bOrder = !!charitiesData[b].order ? charitiesData[b].order : 99;
              return aOrder - bOrder;
            })
            .map((id) => {
              const active = charitiesData[id].active;
              const name = charitiesData[id].name;
              const description = charitiesData[id].description;
              const image_url = charitiesData[id].image_url;
              const image_alt = charitiesData[id].image_alt;

              if (!active) return;
              return (
                <Form.Label key={id} htmlFor="" className="flex gap-3 flex-wrap items-center justify-around">
                  <Form.Check>
                    <Form.Check.Input
                      className="!border-[rgb(33,37,41)]"
                      type="radio"
                      name="foundation"
                      value={name}
                      required={donationIntent}
                    />
                  </Form.Check>
                  <img className="h-[100px] w-[100px]" src={image_url} alt={image_alt} title={image_alt} />
                  <div className="max-w-md ">
                    <p>{name}</p>
                    <p>{description}</p>
                  </div>
                </Form.Label>
              );
            })}
      </div>

      <p className="my-0 text-sm mx-auto text-slate-600">Donation: ${donationAmount || 0}.00</p>
      <Form.Group className="mx-auto w-full mb-3 pt-3">
        <Button size="" className="w-full mx-auto block py-2" type="submit">
          {donationIntent ? "Continue" : "Continue without Donation"}
        </Button>
      </Form.Group>
    </Form.Group>
  );
}
